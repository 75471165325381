<template>
	<main class="guest-page">
		<Full class="guest-page__item guest-page__item--full-block" type="guest" />

		<GuestBenefits class="guest-page__item" />
		<GuestSecurity class="guest-page__item" />
		<GuestVideo class="guest-page__item" />

		<Full class="guest-page__item guest-page__item--full-block" type="owner" />

		<Gallery class="guest-page__reviews" title="Посмотрите отзывы наших арендодателей"
			sub-title="Настоящие истории от людей, сдающих жилье на Теремке" card="ReviewCard"
			:carousel="carouselReviewsSettings" :content="reviews.items" :meta="reviews.meta"
			@more="get('reviews', $event)" />

		<Gallery class="guest-page__item guest-page__item--type" title="Тип жилья" card="MainCard"
			:carousel="carouselTypesSettings" :content="types" :meta="{ total: types.length }" />

		<Gallery class="guest-page__item" title="Условия размещения" card="MainCard" :carousel="carouselTypesSettings"
			:content="terms" :meta="{ total: terms.length }" />

		<Gallery class="guest-page__item" title="Идеи путешествий"
			sub-title="Не знаете куда поехать? Читайте подборки красивых и интересных мест от наших специалистов"
			card="ArticleCard" :carousel="carouselIdeasSettings" :content="ideas.items" :meta="ideas.meta"
			@more="get('ideas', $event)" />

		<Gallery class="guest-page__item" title="Актуальные и полезные статьи"
			sub-title="Свежие новости для арендодателей и арендаторов" card="ArticleCard" type-card="small"
			:carousel="carouselArticlesSettings" :content="articles.items" :meta="articles.meta"
			@more="get('articles', $event)" />

		<Rent class="guest-page__item" />
	</main>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { arrToObj } from '@/utils/normalizeData'
import mods from '@/constants/mods'
import articlesType from '@/constants/articlesType'
import { getDateInQueryFormat } from "@/utils/dateTime";
import GuestBenefits from "@/components/views/home/guest/GuestBenefits.vue";
import GuestSecurity from "@/components/views/home/guest/GuestSecurity.vue";
import GuestVideo from "@/components/views/home/guest/GuestVideo.vue";

function _createPlacesLink(query) {
	if (!query.children) query.children = 0;
	if (!query.guests) query.guests = 2;
	if (!query.city) query.city = "c2deb16a-0330-4f05-821f-1d09c93331e6";
	// if (!query.date_from) query.date_from = getDateInQueryFormat(new Date());
	// if (!query.date_to) query.date_to = getDateInQueryFormat(new Date(2099, 11, 5));

	return { name: 'Places', query: query }
}

const _about = [
	{
		name: 'Снимать выгоднее',
		text: 'Цены ниже, чем в гостиницах, хостелах и квартирах посуточно',
		img: require('@/assets/img/pages/home/safety.svg'),
		width: 174,
		height: 262
	},
	// {
	// 	name: 'Надёжно',
	// 	text: 'Каждое жилье на Теремке застраховано за наш счет',
	// 	img: require('@/assets/img/pages/home/reliably.svg'),
	//   width: 343,
	//   height: 180
	// },
	{
		name: 'Позитивные люди',
		text: 'Мы делаем так, чтобы общение гостя и хозяина было комфортным, а проживание безопасным',
		img: require('@/assets/img/pages/home/profitable.svg'),
		width: 355,
		height: 260
	},
	{
		name: 'Множество удобств',
		text: 'Дополнительный набор удобств от хозяев, которые не встречаются на других сервисах поиска жилья',
		img: require('@/assets/img/pages/home/comfortable.svg'),
		width: 530,
		height: 300
	}
]
// const _configPopularList = [
// 	{
// 		name: 'Москва',
// 		key: 'moscow',
// 		number: 0,
// 		img: require('@/assets/img/pages/home/city1.jpg'),
//     link: _createPlacesLink({ city: "0c5b2444-70a0-4932-980c-b4dc0d3f02b5" })
// 	},
// 	{
// 		name: 'Санкт-Петербург',
// 		key: 'saint-petersburg',
// 		number: 0,
// 		img: require('@/assets/img/pages/home/city2.png'),
//     link: _createPlacesLink({ city: "c2deb16a-0330-4f05-821f-1d09c93331e6" })
// 	},
// 	// {
// 	// 	name: 'Шерегеш',
// 	// 	key: 'sheregesh',
// 	// 	number: '',
// 	// 	img: require('@/assets/img/pages/home/city3.png'),
//   //   link: _createPlacesLink({ city: "27123674-cf73-4f0b-99ac-a467224fb289" })
// 	// },
// 	{
// 		name: 'Сочи',
// 		key: 'sochi',
// 		number: 0,
// 		img: require('@/assets/img/pages/home/city4.png'),
//     link: _createPlacesLink({ city: "79da737a-603b-4c19-9b54-9114c96fb912" })
// 	},
// ]
const _configTypesList = [
	{
		name: 'Квартиры',
		key: 'flat',
		img: require('@/assets/images/new-images/flat.webp'),
		number: 0,
		link: _createPlacesLink({ type: 1 })
	},
	{
		name: 'Дом',
		key: 'house',
		img: require('@/assets/images/new-images/house.webp'),
		number: 0,
		link: _createPlacesLink({ type: 2 })
	},
	{
		name: 'Апартаменты',
		key: 'apartments',
		img: require('@/assets/img/pages/home/apartments.png'),
		number: 0,
		link: _createPlacesLink({ type: 3 })
	}
]
const _configTermsList = [
	{
		name: 'Жилье целиком',
		key: 'full',
		img: require('@/assets/images/new-images/housing-authority.webp'),
		number: 0,
		link: _createPlacesLink({ room_type: 1 })
	},
	{
		name: 'Отдельная комната',
		key: 'room',
		img: require('@/assets/images/new-images/separate-room.webp'),
		number: 0,
		link: _createPlacesLink({ room_type: 2 })
	},
	{
		name: 'Место в комнате',
		key: 'place_room',
		img: require('@/assets/images/new-images/space-in-room.webp'),
		number: 0,
		link: _createPlacesLink({ room_type: 3 })
	}
]

export default {
	name: 'Guest',
	components: {
		GuestVideo,
		GuestSecurity,
		GuestBenefits,
		Full: defineAsyncComponent(() =>
			import('@/components/views/home/FullBlock')
		),
		Rent: defineAsyncComponent(() =>
			import('@/components/views/home/guest/Rent')
		),
		Gallery: defineAsyncComponent(() =>
			import('@/components/views/home/Gallery')
		),
		MainCard: defineAsyncComponent(() =>
			import('@/components/views/home/cards/Main')
		)
	},
	data() {
		return {
			reviews: {
				items: [],
				meta: {}
			},
			articles: {
				items: [],
				meta: {}
			},
			ideas: {
				items: [],
				meta: {}
			},
			counters: {},

			getAbortControllers: new Map(),
		}
	},
	created() {
		this.$store.commit('changeMode', mods.GUEST)
	},
	async beforeMount() {
		await Promise.all([
			this.get('reviews'),
			this.get('articles'),
			this.get('ideas'),
			this.getCounters()
		])
	},
	beforeUnmount() {
		for (let ac of this.getAbortControllers.values()) {
			ac.abort()
		}
	},
	methods: {
		async get(type, isUpdate = false) {
			if (this.getAbortControllers.get(type)) {
				return
			}

			let page = 1

			if (isUpdate) {
				const { meta } = this[type]

				if (meta.current_page < meta.last_page) {
					page = meta.current_page + 1
				} else {
					return // заканчиваем загружать, так как страницы закончились
				}
			}

			const ac = new AbortController()
			this.getAbortControllers.set(type, ac)

			const result = await this.$api.articles.getAll({
				type: this._configForGetDataFunction[type].type_article,
				page,
				limit: this._configForGetDataFunction[type].limit
			}, { controller: ac })

			if (result?.status) {
				this[type] = {
					items: [...this[type].items, ...result.response.items],
					meta: result.response.meta
				}
			}

			this.getAbortControllers.delete(type)
		},
		async getCounters() {
			const ac = new AbortController()
			this.getAbortControllers.set('counters', ac)

			const counters = await this.$api.lists.getCounters({ controller: ac })

			if (counters?.status) {
				const counterResultToArray = counters.response.reduce(
					(prev, itm) => prev.concat(itm.items),
					[]
				)
				this.counters = arrToObj(counterResultToArray, 'name')
			}

			this.getAbortControllers.delete('counters')
		}
	},
	computed: {
		_configForGetDataFunction() {
			return {
				reviews: {
					type_article: articlesType.REVIEWS,
					limit: 4
				},
				articles: {
					type_article: articlesType.GUEST_ARTICLES,
					limit: 4
				},
				ideas: {
					type_article: articlesType.IDEAS,
					limit: 6
				}
			}
		},
		about() {
			return _about
		},
		popular() {
			return _configPopularList.map((itm) => ({
				...itm,
				number: this.counters[itm.key]?.count || 0
			}))
		},
		types() {
			return _configTypesList.map((itm) => ({
				...itm,
				number: this.counters[itm.key]?.count || 0
			}))
		},
		terms() {
			return _configTermsList.map((itm) => ({
				...itm,
				number: this.counters[itm.key]?.count || 0
			}))
		},
		carouselAboutSettings() {
			return {
				settings: { itemsToShow: 4, snapAlign: 'start' },
				breakpoints: {
					320: { itemsToShow: 1 },
					600: { itemsToShow: 2 },
					920: { itemsToShow: 3 },
					1200: { itemsToShow: 4 }
				}
			}
		},
		carouselArticlesSettings() {
			return {
				settings: { itemsToShow: 3, snapAlign: 'start', autoplay: 5000 },
				breakpoints: { 320: { itemsToShow: 1, snapAlign: 'start' }, 700: { itemsToShow: 2, snapAlign: 'start' }, 1100: { itemsToShow: 3, snapAlign: 'start' } }
			}
		},
		carouselReviewsSettings() {
			return {
				settings: { itemsToShow: 2, snapAlign: 'start' },
				breakpoints: {
					320: { itemsToShow: 1 },
					1000: { itemsToShow: 2 }
				}
			}
		},
		carouselIdeasSettings() {
			return {
				settings: { itemsToShow: 3, snapAlign: 'start', autoplay: 5000 },
				breakpoints: { 320: { itemsToShow: 1 }, 700: { itemsToShow: 2 }, 1100: { itemsToShow: 3 } }
			}
		},
		carouselPopularSettings() {
			return {
				settings: { itemsToShow: 4, snapAlign: 'start', wrapAround: true },
				breakpoints:
				{
					320: { itemsToShow: 1.5 },
					460: { itemsToShow: 2 },
					920: { itemsToShow: 3, touchDrag: true },
					1100: { itemsToShow: 4, itemsToScroll: 0, touchDrag: false }
				}
			}
		},
		carouselTypesSettings() {
			return {
				settings: { itemsToShow: 3, snapAlign: 'start', },
				breakpoints: { 320: { itemsToShow: 1, snapAlign: 'start' }, 700: { itemsToShow: 2, snapAlign: 'start', touchDrag: true }, 1100: { itemsToShow: 3, snapAlign: 'start', touchDrag: false } }
			}
		},
	}
}
</script>

<style lang="sass">
.guest-page
	&__item
		margin-bottom: 80px

		&--full-block
			margin-bottom: 85px

		&--type
			margin-bottom: 55px
		
	&__reviews
		margin-bottom: 55px
</style>
