<script setup>

</script>

<template>
	<div class="guest-security">
		<div class="container">
			<div class="guest-security__body">
				<img class="guest-security__img" src="@/assets/img/svg/views/home/guest-security.svg" alt="С нами просто и безопасно" decoding="async">
				<div class="guest-security__item">
					<h2 class="guest-security__title">С нами просто и безопасно</h2>
					<p class="guest-security__text">Каждый житель Теремка проходит верификацию, которая помогает нам повысить безопасность нашего комьюнити, путешествовать и принимать гостей, зная, что вас ожидает встреча с проверенным человеком</p>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.guest-security{
	&__body{
		border-radius: 10px;
		background: #F8F8F8;
		padding: 40px 25px 0;
		overflow: hidden;
		display: flex;
		gap: 187px;
		justify-content: center;
	}
	&__img{
		width: 245px;
		height: 252px;
		align-self: flex-end;
	}
	&__item{
		display: flex;
		flex-direction: column;
		gap: 20px;
		margin-top: 40px;
		padding-bottom: 80px;
	}
	&__title{
		color: #2A3043;
		font-size: 24px;
		font-weight: 700;
		margin: 0;
		padding: 0;
	}
	&__text{
		color: #979797;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		margin: 0;
		padding: 0;
		max-width: 370px;
	}
	@media screen and (max-width: 912px){
		&__body{
			gap: 68px;
			padding: 32px 25px 0;
		}
		&__item{
			display: flex;
			flex-direction: column;
			gap: 20px;
			margin-top: 23px;
			padding-bottom: 55px;
		}
		&__img{
			width: 210px;
			height: 216px;
		}
	}
	@media screen and (max-width: 570px){
		&__body{
			flex-direction: column-reverse;
			align-items: center;
			padding: 25px 25px 0;
			gap: 25px;
		}
		&__item{
			margin-top: 0;
			padding-bottom: 0;
			gap: 10px;
		}
		&__img{
			align-self: center;
			width: 194px;
			height: 200px;
		}
	}
}
</style>
