<script setup>

</script>

<template>
	<div class="guest-video">
		<div class="container">
			<div class="guest-video__body">
				<div class="guest-video__item">
					<h2 class="guest-video__title">
						Теремок — сообщество для тех, кому нравится принимать гостей
					</h2>
					<p class="guest-video__text">Россия — страна, жители которой всегда отличались особым радушием и гостеприимностью. Какой бы ни была цель вашего визита, у нас всегда можно найти место, чтобы остановиться и почувствовать себя гостем, а не просто постояльцем. Мы решили сделать такое сообщество, находясь в котором, вы сможете не только комфортно переночевать, но и обрести новые знакомства, хорошо провести время и сделать свой вклад в развитие системы жилья от человека к человеку. Кроме того, вы можете сами в любой момент открыть свои двери для других участников сообщества.</p>
				</div>
				<iframe class="guest-video__main" src="https://www.youtube.com/embed/w2oeGfTJmMo?si=_Rj2TZ4MZxetYurs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.guest-video{
	&__body{
		display: flex;
		gap: 20px;
		align-items: flex-start;
		& > div{
			flex: 1 1 ;
		}
	}
	&__item{
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__title{
		color: #2A3043;
		font-size: 24px;
		font-weight: 700;
		line-height: normal;
		padding: 0;
		margin: 0;
	}
	&__text{
		color: #979797;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		padding: 0;
		margin: 0;
	}
	&__main{
		position: relative;
		overflow: hidden;
		max-width: 590px;
		aspect-ratio: 590 / 347;
		width: 100%;
		border-radius: 10px;
	}
	@media screen and (max-width: 912px){
		&__body{
			flex-direction: column-reverse;
			align-items: center;
		}
		&__main{
			max-width: 100%;
		}
		&__item{
			flex-direction: row;
			justify-content: space-between;
			width: 100%;
		}
		&__title{
			max-width: 285px;
			width: 100%;
			flex-shrink: 0;
		}
		&__text{
			max-width: 332px;
		}
	}
	@media screen and (max-width: 600px){
		&__item{
			flex-direction: column;
		}
		&__text,
		&__title{
			max-width: 100%;
		}
	}
}
</style>
