<script setup>
const _items = {
	1: {
		title: 'Путешествуйте, становитесь гостем',
		text: 'Деловая встреча? Отпуск? Простая экскурсия? С Теремком можно спланировать любую поездку, зная, что вам будут рады в любом городе.',
		img: require('@/assets/img/svg/views/home/benefits-airplane.svg'),
	},
	2: {
		title: 'Принимайте гостей сами',
		text: 'Открывайте двери для гостей, которые едут в ваш город, разместив своё жильё на сайте. Знакомьтесь с людьми, которые любят путешествия так же, как вы.',
		img: require('@/assets/img/svg/views/home/benefits-handshake.svg'),
	},
	3: {
		title: 'Вступайте в наше сообщество',
		text: 'Теремок — это сообщество людей, близких друг другу по духу. Наши всегда открыты не только для размещения, но и для любого вида взаимопомощи между участниками комьюнити.',
		img: require('@/assets/img/svg/views/home/benefits-users.svg'),
	},
}
</script>

<template>
	<div class="guest-benefits">
		<div class="container">
			<div class="guest-benefits__body">
				<div v-for="item in _items" :key="item.title" class="guest-benefits__item">
					<div class="guest-benefits__top">
						<img :src="item.img" :alt="item.title" decoding="async">
					</div>
					<h2 class="guest-benefits__middle">{{item.title}}</h2>
					<div class="guest-benefits__bottom">
						{{item.text}}
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<style scoped lang="scss">
.guest-benefits{

	&__body{
		display: flex;
		gap: 20px;
		justify-content: space-between;
	}
	&__item{
		display: flex;
		flex-direction: column;
		gap: 20px;
	}
	&__top{
		border-radius: 10px;
		background: #F8F8F8;
		width: 80px;
		height: 80px;
		margin-bottom: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		img{
			width: 40px;
			height: 40px;
		}
	}
	&__middle{
		color: #2A3043;
		font-size: 18px;
		font-weight: 700;
		line-height: 1;
		margin: 0;
		padding: 0;
	}
	&__bottom{
		color: #979797;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		max-width: 342px;
	}
	@media screen and (max-width: 912px){
		&__top{
			width: 60px;
			height: 60px;
			img{
				width: 30px;
				height: 30px;
			}
		}
	}

	@media screen and (max-width: 700px){
		&__top{
			margin-bottom: 10px;
		}
		&__item{
			gap: 10px;
		}
		&__body{
			flex-direction: column;
			gap: 25px;
		}
		&__bottom{
			max-width: 100%;
		}
	}
	@media screen and (max-width: 479px){
		&__top{
			width: 50px;
			height: 50px;
			img{
				width: 25px;
				height: 25px;
			}
		}
	}

}
</style>
